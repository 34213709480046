import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { get } from '@rails/request.js'
import currentUserId from './_lib/user/currentUserId.js'
import { getHistory, addToHistory } from './_lib/history/addToHistory.js'

export default class extends Controller {
  static targets = [
    'citySelect',
    'searchInput',
    'closeButton',
    'submitButton',
    'countrySelect',
    'onlineCheckbox',
    'categorySelect',
    'searchResultModal'
  ]
  static values = {
    url: String,
    history: String,
    searchParams: String,
    advancedSearchParams: String,
    keywordsParams: String,
    requestValue: { type: Boolean, default: false }
  }

  
  connect() {
    this.backdrop = document.createElement("div");
    this.loader = document.querySelector("#turbo-loader-container")
    this.loaderBackdrop = document.querySelector("#turbo-loader-backdrop")
    this.searchWithDebounce = debounce(this.searchWithDebounce, 500)

    document.querySelector('#search-form')?.addEventListener('submit', event => event.preventDefault())
    document.querySelector('#advanced-search-form')?.addEventListener('submit', event => event.preventDefault())
  }

  prepareBeforeResponce() {
    this.requestValue = true
    this.loader.classList.remove('d-none')
    this.loaderBackdrop.classList.remove('d-none')
  }

  clearAfterResponce() {
    this.requestValue = false
    this.loader.classList.add('d-none')
    this.loaderBackdrop.classList.add('d-none')
  }

  invalidate(query) {
    return !query || query.length === 0 || query.length > 50
  }

  setQueryValue(query) {
    const category = this.categorySelectTarget.value
    const onlineCheckbox = this.hasOnlineCheckboxTarget && this.onlineCheckboxTarget.checked
    const country = this.hasCitySelectTarget && this.countrySelectTarget.value
    const city = this.hasCitySelectTarget && this.citySelectTarget.value
    if (category) query['[category_ids]'] = [category]
    if (this.hasOnlineCheckboxTarget && !onlineCheckbox) {
      query['[offline]'] = !onlineCheckbox
      if (country) query['[country_id]'] = country
      if (city) query['[city_id]'] = city
    }
    return query
  }

  // Main search with debounce
  async searchWithDebounce(event) {
    if (event.key === "Escape" || event.key === 'Enter') return // ESC or Enter мають інший функціонал

    const valueSearch = this.searchInputTarget?.value.trim()
    if (this.invalidate(valueSearch)) return

    if (!currentUserId()) addToHistory(valueSearch)
    await this.autoSearch(valueSearch)
  }

  // category or city select and immediatly autosearch (if input not empty)
  searchAfterSelect = async () => {
    this.checkFieldsAndEnableButton()
    const valueSearch = this.searchInputTarget.value.trim()
    if (this.invalidate(valueSearch)) return
    
    await this.autoSearch(valueSearch)
  }

  // Autosearch after click on recent search item
  async searchByHistory(event) {
    const valueSearch = event.currentTarget.dataset.searchParams
    if (this.invalidate(valueSearch)) return

    await this.autoSearch(valueSearch, 'setSearchValue')
  }

  // autosearch Fired!
  autoSearch = async (valueSearch, setSearchValue) => {
    if (this.requestValue) return
    
    this.prepareBeforeResponce()
    this.requestValue = true
    const url = this.urlValue
    let query = {}
    query['[query]'] = valueSearch
    query = this.setQueryValue(query)
    const response = await get(url, {
      query,
      responseKind: 'turbo-stream'
    })
    if (response.ok) {
      this.clearAfterResponce()
      if (setSearchValue !== undefined) {
        this.searchInputTarget.value = valueSearch
        this.checkFieldsAndEnableButton()
      }
    }
  }
  
  hideSearchedResult() {
    if (document.querySelector('#autosearch_results').childElementCount === 0 ) return

    if (this.hasCloseButtonTarget) {
      // close search result after click on other select
      this.hideBackdrop() // this.closeButtonTarget.click() 
      if (this.hasSearchResultModalTarget) this.searchResultModalTarget.remove()
    }
  }

  checkFieldsAndEnableButton() {
    const valueSearch = this.searchInputTarget.value.trim()
    const category = this.categorySelectTarget.value
    const button = this.submitButtonTarget
    if (valueSearch || category) {
      button.disabled = false
    } else {
      button.disabled = true
    }
  }

  goToAdvancedSearch(event) {
    event.preventDefault()
    event.stopPropagation()
    const valueSearch = this.searchInputTarget.value.trim()
    const categoryId = this.categorySelectTarget.value
    const goToUrl = new URL(createRootUrlWithLocale('search'))
    const searchParams = goToUrl.searchParams
    searchParams.set('type', 'product')
    if (valueSearch) searchParams.set('search', valueSearch)
    if (categoryId) searchParams.set('category_id', categoryId)
    Turbo.visit(goToUrl)
  }

  goToAdvancedServiceSearch(event) {
    if (this.element.id !== 'service-page-search-form') return
    
    const inputSearch = this.hasSearchInputTarget ? this.searchInputTarget.value.trim() : ''
    if (inputSearch.length <= 0 || inputSearch.length > 70) return
    
    event.preventDefault()
    event.stopPropagation()
    const valueSearch = this.searchInputTarget.value.trim()
    // const categoryId = this.categorySelectTarget.value
    const goToUrl = new URL(createRootUrlWithLocale('search'))
    const searchParams = goToUrl.searchParams
    searchParams.set('type', 'service')
    if (valueSearch) searchParams.set('search', valueSearch)
    // if (categoryId) searchParams.set('category_id', categoryId)
    Turbo.visit(goToUrl)
  }

  // after show autosearch results add event listener to document and backdrop
  searchResultModalTargetConnected(element) {
    this.handleClickOutside = this.handleClickOutside.bind(this);
    document.addEventListener("click", this.handleClickOutside);
    this.showBackdrop();
  }

  searchResultModalTargetDisconnected(element) {
    document.removeEventListener("click", this.handleClickOutside);
    this.hideBackdrop();
    this.checkFieldsAndEnableButton()
  }

  showBackdrop() {
    this.backdrop.classList.add("backdrop");
    document.body.appendChild(this.backdrop);
  }

  hideBackdrop() {
    if (this.backdrop) this.backdrop.remove();
  }

  get insideDropdowm() {
    return this.hasSearchResultModalTarget && this.searchResultModalTarget.contains(event.target);
  }

  get insideInput() {
    return this.hasSearchInputTarget && this.searchInputTarget.contains(event.target);
  }

  handleClickOutside(event) {
    // Перевіряємо, чи був зроблений клік поза dropdown чи input полем search форми
    if (!this.insideDropdowm && !this.insideInput) {
      this.hideSearchedResult();
    }
  };

  refreshSearch() {
    const valueSearch = this.searchInputTarget.value.trim()
    if (this.invalidate(valueSearch) || this.hasSearchResultModalTarget) return

    this.autoSearch(valueSearch)
  }

  // user history search seaction
  userHistory = async (event) => {
    const urlUserHistory = event.target.dataset.autosearchHistoryValue
    const inputValue = event.target.value.trim()
    if (inputValue) return

    if (currentUserId()) {
      await this.getUserHistory(urlUserHistory) // for authenticate user
    } else {
      const localHistory = getHistory()    // for guest
      await this.getUserLocalHistory(urlUserHistory, localHistory)
    }
  }

  // user history search fired! (if current_user present)
  getUserHistory = async (url) => {
    await get(url.toString(), {
      query: {
        page_search: true
      },
      responseKind: 'turbo-stream'
    })
  }

  // user history search fired! (guest only)
  getUserLocalHistory = async (url, localHistory) => {
    await get(url.toString(), {
      query: {
        local_history: localHistory,
        page_search: true
      },
      responseKind: 'turbo-stream'
    })
  }

  fillSearch(event) {
    const searchInput = document.querySelector('[data-page-autosearch-target="searchInput"]')
    if (searchInput) {
      searchInput.value = event.target.textContent.trim()
      const searchEvent = new Event('keyup', { bubbles: true })
      searchInput.dispatchEvent(searchEvent)
    }
  }
}